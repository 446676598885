import './App.css';
import { CircularProgress } from '@mui/material';
import { useState, useMemo, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { io } from 'socket.io-client'

function App() {
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);
  const [sortState, setSortState] = useState({}); // New state for sorting per table

  function createData(id, RaceNumber, Selection, 
    win,
     place, 
    // winplace, 
     WinLimit,
     PlaceLimit,
    //  WinPlaceLimit
    ) {
    return { id, RaceNumber, Selection, 
      win,
       place,
      //  winplace, 
       WinLimit,
       PlaceLimit,
      //  WinPlaceLimit
       };
  }

  function descendingComparator(a, b, orderBy) {
    if (orderBy === 'RaceNumber') {
      return Number(b[orderBy]) - Number(a[orderBy]);
    } else {
      if (b[orderBy] < a[orderBy]) return -1;
      if (b[orderBy] > a[orderBy]) return 1;
      return 0;
    }
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    // { id: 'RaceNumber', numeric: true, disablePadding: false, label: 'R#' },
    { id: 'Selection', numeric: true, disablePadding: false, label: 'Horse' },
    { id: 'win', numeric: true, disablePadding: false, label: 'Win' },
    { id: 'WinLimit', numeric: true, disablePadding: false, label: 'WinLimit' },
    // { id: 'winplace', numeric: true, disablePadding: false, label: 'WinPlace' },
    // { id: 'WinPlaceLimit', numeric: true, disablePadding: false, label: 'WinPlaceLimit' },
    { id: 'place', numeric: true, disablePadding: false, label: 'Place' },
    { id: 'PlaceLimit', numeric: true, disablePadding: false, label: 'PlaceLimit' },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'left' : 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ padding: '4px' }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    onRequestSort: PropTypes.func.isRequired,
  };

  const handleRequestSort = (event, property, raceNumber) => {
    const isAsc = sortState[raceNumber]?.orderBy === property && sortState[raceNumber]?.order === 'asc';
    setSortState({
      ...sortState,
      [raceNumber]: {
        order: isAsc ? 'desc' : 'asc',
        orderBy: property,
      },
    });
  };

  const sortedRows = useMemo(() => {
    return stableSort(rows, getComparator('asc', 'RaceNumber'));
  }, [rows]);

  const groupedRows = useMemo(() => {
    const groups = sortedRows.reduce((acc, row) => {
      if (!acc[row.RaceNumber]) acc[row.RaceNumber] = [];
      acc[row.RaceNumber].push(row);
      return acc;
    }, {});
    return groups;
  }, [sortedRows]);

  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);
  const [test, setTest] = useState('');

  useEffect(() => {
    const fetchData = () => {
      setLoader(true);
      // const url = 'https://api.walletly.ai/api/v4/pass/get/bett';
      // const url = 'http://localhost:8080/v4/pass/get/bett';
      const url = 'https://race.bigplay.ai/api/v4/pass/get/bett';

      axios.get(url)
        .then(response => {
          console.log('response',response);
          const fetchedData = response.data.data.map((item, index) =>
            createData(index, item.RaceNumber, item.Selection, 
              item.win, 
              item.place,
              //  item.winplace, 
              item.WinLimit,
              item.PlaceLimit,
              // item.WinPlaceLimit
            )
          );
          console.log('fetchedData-',fetchedData);
          setRows(fetchedData);
          setLoader(false);
        })
        .catch(error => {
          console.error('Error fetching data: ', error);
          setLoader(false);
        });
      setTest('1')  
    };

    fetchData();
    // const interval = setInterval(fetchData, 5000); // Fetch data every 30 seconds

    // return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);


  // useEffect(() => {
  //   if (!socket) return;
  
  //   socket.on('mergedData', (mergedData) => {
  //     setLoader(true);
  //     console.log('Received mergedData:', mergedData);
  
  //     // Create a copy of the current rows state
  //     const updatedRows = [...rows];
  
  //     // Update rows that match the incoming mergedData
  //     mergedData.forEach((socketItem) => {
  //       const index = updatedRows.findIndex((row) => (
  //         row.Dividend === socketItem.Dividend &&
  //         row.RaceNumber === socketItem.RaceNumber &&
  //         row.Selection === socketItem.Selection
  //       ));
        
  //       if (index !== -1) {
  //         // Update the specific row in the copied array
  //         updatedRows[index] = createData(
  //           index,
  //           socketItem.RaceNumber,
  //           socketItem.Selection,
  //           socketItem.win,
  //           socketItem.place,
  //           socketItem.winplace
  //         );
  //       }
  //     });
  
  //     // Set the updated rows back to state
  //     setRows(updatedRows);
  //     setLoader(false);
  //   });
  
  //   // Cleanup socket connection
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, [socket, rows]);
  
  useEffect(() => {
    const SOCKET_URL = 'https://race.bigplay.ai';
    const socket = io(SOCKET_URL, {
      transports: ['websocket'],
      reconnectionAttempts: 5,
      reconnectionDelay: 1000
    });
  
    socket.on('connect', () => {
      console.log('Connected to the WebSocket server');
    });
  
    socket.on('mergedData', (mergedData) => {
      setLoader(true);
      console.log('Received mergedData:', mergedData);
  
      // Update rows state immutably
      setRows(currentRows => {
        const updatedRows = currentRows.map(row => {
          const socketItem = mergedData.find(item => (
            item.RaceNumber === row.RaceNumber && 
            item.Selection === row.Selection
          ));
  
          if (socketItem) {
            // Return a new object with updated fields
            return {
              ...row,
              win: socketItem.win,
              place: socketItem.place,
              // winplace: socketItem.winplace,
              WinLimit: socketItem.WinLimit,
              PlaceLimit: socketItem.PlaceLimit
              // WinPlaceLimit: socketItem.WinPlaceLimit
            };
          }
          return row; // Return the original row if no match found
        });
  
        setLoader(false);
        return updatedRows; // Update the rows state with the new array
      });
    });
  
    setSocket(socket);
  
    return () => {
      socket.disconnect();
    };
  }, []);
  

  // useEffect(() => {
  //   const SOCKET_URL = 'https://race.bigplay.ai';
  //   const socket = io(SOCKET_URL, {
  //     transports: ['websocket'],
  //     reconnectionAttempts: 5,
  //     reconnectionDelay: 1000
  //   });
  
  //   socket.on('connect', () => {
  //     console.log('Connected to the WebSocket server');
  //   });
  
  //   socket.on('mergedData', (mergedData) => {
  //     setLoader(true);
  //     console.log('Received mergedData:', mergedData);

  //     const fetchedData = mergedData.map((item, index) =>
  //       createData(index, item.RaceNumber, item.Selection, item.win, item.place, item.winplace)
  //     );

  //     for (const [index, value] of rows.entries()){
  //       for (const [index1, value1] of mergedData.entries()){
  //         if(value.RaceNumber === value1.RaceNumber && value.Selection === value1.Selection){
  //           rows[index] = {...value, place: value1.place, win: value1.win, winplace: value1.winplace}
  //         }
  //       }
  //     }
  //     // console.log('fetchedData-',fetchedData);
  //     setRows(rows);
  //     setLoader(false);
  
  //   });
  
  //   setSocket(socket);
  
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);
  

  // useEffect(() => {
  //   // Use environment variables or conditional logic to choose the correct URL
  //   const SOCKET_URL = 'https://race.bigplay.ai';
    
  //   // Connect to the socket.io server
  //   const socket = io(SOCKET_URL, {
  //     transports: ['websocket'], // Use WebSocket transport explicitly
  //     reconnectionAttempts: 5,   // Set reconnection attempts
  //     reconnectionDelay: 1000    // Set delay between reconnections
  //   });
  
  //   socket.on('connect', () => {
  //     console.log('Connected to the WebSocket server');
  //   });
  
  //   socket.on('message', (message) => {
  //     console.log('Received message:', message);
  //   });
  
  //   socket.on('mergedData', (mergedData) => {
  //     setLoader(true);
  //     console.log('Received mergedData:', mergedData);
  
  //     setRows(currentRows => {
  //       const updatedRows = currentRows.map(row => {
  //         const socketItem = mergedData.find(item => (
  //           item.Dividend === row.Dividend &&
  //           item.RaceNumber === row.RaceNumber &&
  //           item.Selection === row.Selection
  //         ));
  
  //         if (socketItem) {
  //           // Update the matched row with new data
  //           return {
  //             ...row,
  //             win: socketItem.win,
  //             place: socketItem.place,
  //             winplace: socketItem.winplace
  //           };
  //         }
  //         return row; // Return the original row if no match found
  //       });
  
  //       setLoader(false);
  //       return updatedRows;
  //     });
  //   });
  
  //   // Store the socket instance in state
  //   setSocket(socket);
  
  //   // Clean up socket connection when the component unmounts
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, [test]); // Empty dependency array ensures this effect runs only once on component mount
  


  // useEffect(() => {
  //   // Use environment variables or conditional logic to choose the correct URL
  //   const SOCKET_URL = 'https://race.bigplay.ai';
    
  //   // Connect to the socket.io server
  //   const socket = io(SOCKET_URL, {
  //     transports: ['websocket'], // Use WebSocket transport explicitly
  //     reconnectionAttempts: 5,   // Set reconnection attempts
  //     reconnectionDelay: 1000    // Set delay between reconnections
  //   });

  //   socket.on('connect', () => {
  //     console.log('Connected to the WebSocket server');
  //   });

  //   socket.on('message', (message) => {
  //     console.log('Received message:', message);
  //   });


  //   // socket.on('mergedData', (mergedData) => {
  //   //   setLoader(true);
  //   //   console.log('Received mergedData:', mergedData);
  
  //   //   // Create a copy of the current rows state
  //   //   const updatedRows = [...rows];
  
  //   //   // Update rows that match the incoming mergedData
  //   //   mergedData.forEach((socketItem) => {
  //   //     console.log('socketItem',socketItem);
  //   //     const index1 = updatedRows.findIndex((row) => (
  //   //       console.log('1',row)
  //   //     ));
  //   //     const index = updatedRows.findIndex((row) => (
  //   //       row.Dividend === socketItem.Dividend &&
  //   //       row.RaceNumber === socketItem.RaceNumber &&
  //   //       row.Selection === socketItem.Selection
  //   //     ));
        
  //   //     if (index !== -1) {
  //   //       // Update the specific row in the copied array
  //   //       updatedRows[index] = createData(
  //   //         index,
  //   //         socketItem.RaceNumber,
  //   //         socketItem.Selection,
  //   //         socketItem.win,
  //   //         socketItem.place,
  //   //         socketItem.winplace
  //   //       );
  //   //     }
  //   //   });

  //   //   console.log('rows',rows);
  //   //   console.log('updatedRows',updatedRows);
  
  //   //   // Set the updated rows back to state
  //   //   setRows(updatedRows);
  //   //   setLoader(false);
  //   // });

  //   socket.on('mergedData', (mergedData) => {
  //     setLoader(true);
  //     console.log('Received mergedData:', mergedData);
  //     const fetchedData1 = mergedData.map((item, index) =>
  //       console.log('item',item)
  //       // createData(index, item.RaceNumber, item.Selection, item.win, item.place, item.winplace)
  //     );
  //     const fetchedData = mergedData.map((item, index) =>
  //       createData(index+rows?.length, item.RaceNumber, item.Selection, item.win, item.place, item.winplace)
  //     );
  //     console.log('fetchedData',fetchedData);
  //     console.log('rows',rows);
  //     rows.push(fetchedData)
  //     setRows(rows);
  //     setLoader(false);
  //   });

  //   // Store the socket instance
  //   setSocket(socket);

  //   // Clean up the connection when the component unmounts
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  // useEffect(() => {
  //   // Connect to the socket.io server
  //   // const socket = io('http://localhost:8084');
  //   const socket = io('https://race.bigplay.ai/api');
  //   socket.on('connect', () => {
  //     console.log('Connected to the WebSocket server');
  //   });


  //   // Listen for messages
  //   socket.on('message', (message) => {
  //     console.log('message',message);
  //   });

  //   socket.on('mergedData', (mergedData) => {
  //     setLoader(true);
  //     console.log('mergedData',mergedData);
  //     const fetchedData = mergedData.map((item, index) =>
  //       createData(index, item.RaceNumber, item.Selection, item.win, item.place, item.winplace)
  //     );
  //     setRows(fetchedData);
  //     setLoader(false);
  //     // setMessages((prevMessages) => [...prevMessages, message]);
  //   });

  //   // Store the socket instance
  //   setSocket(socket);

  //   // Clean up the connection when the component unmounts
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  return (
    <div>
      {console.log('final',rows)}
      {loader && <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} size={50} color={'inherit'} />}
      <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {Object.keys(groupedRows).map(raceNumber => {
          const { order, orderBy } = sortState[raceNumber] || { order: 'asc', orderBy: 'RaceNumber' };
          const sortedRaceRows = stableSort(groupedRows[raceNumber], getComparator(order, orderBy));

          return (
            <Paper sx={{  mb: 2 }} key={raceNumber} elevation={3}>
              {/* width: '30%', */}
              <h4 style={{ margin: '8px' }}>Race Number: {raceNumber}</h4>
              <TableContainer>
                <Table size="small" aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(event, property) => handleRequestSort(event, property, raceNumber)}
                  />
                  <TableBody>
                    {sortedRaceRows.map((row, index) => (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        {/* <TableCell align="left" sx={{ padding: '4px' }}>{row?.RaceNumber}</TableCell> */}
                        <TableCell align="left" sx={{ padding: '4px' }}>{row?.Selection}</TableCell>
                        <TableCell align="left" sx={{ padding: '4px' }}>{row?.win?.toLocaleString()}</TableCell>
                        <TableCell align="left" sx={{ padding: '4px' }}>{row?.WinLimit?.toLocaleString()}</TableCell>
                        {/* <TableCell align="left" sx={{ padding: '4px' }}>{row?.winplace?.toLocaleString()}</TableCell> */}
                        {/* <TableCell align="left" sx={{ padding: '4px' }}>{row?.WinPlaceLimit?.toLocaleString()}</TableCell> */}
                        <TableCell align="left" sx={{ padding: '4px' }}>{row?.place?.toLocaleString()}</TableCell>
                        <TableCell align="left" sx={{ padding: '4px' }}>{row?.PlaceLimit?.toLocaleString()}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          );
        })}
      </Box>
    </div>
  );
}

export default App;
